<template>
  <div>
    <head>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

      <!-- icon files -->
      <link
              href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
              rel="stylesheet">
      <link rel="stylesheet" href="css/icon_fonts/css/icon_set_1.css">
      <link rel="stylesheet" href="css/icon_fonts/css/icon_set_2.css">
      <link rel="stylesheet" href="css/icon_fonts/css/icon_set_3.css">
      <link rel="stylesheet" href="css/icon_fonts/css/icon_set_4.css">

      <!-- Vendor styles -->
      <link rel="stylesheet" href="vendors/material-design-iconic-font/css/material-design-iconic-font.min.css">
      <link rel="stylesheet" href="vendors/animate.css/animate.min.css">
      <link rel="stylesheet" href="vendors/jquery-scrollbar/jquery.scrollbar.css">
      <link rel="stylesheet" href="vendors/fullcalendar/fullcalendar.min.css">
      <link rel="stylesheet" href="vendors/bootstrap-select/css/bootstrap-select.min.css">

      <link rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/7.1.96/css/materialdesignicons.min.css"
            integrity="sha512-NaaXI5f4rdmlThv3ZAVS44U9yNWJaUYWzPhvlg5SC7nMRvQYV9suauRK3gVbxh7qjE33ApTPD+hkOW78VSHyeg=="
            crossorigin="anonymous" referrerpolicy="no-referrer"/>


      <!-- // Add the new slick-theme.css if you want the default styling -->
      <link rel="stylesheet" href="vendors/slick/slick.css"/>
      <link rel="stylesheet" href="vendors/slick/slick-theme.css"/>

      <!-- <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css"/> -->

      <!-- App styles -->
      <link rel="stylesheet" href="landing-page.css">
      <link rel="stylesheet" href="app.min.css">
      <link rel="stylesheet" href="landing-assets/css/input100.css">
      <link rel="stylesheet" href="landing-icons/font/flaticon.css">


    </head>

    <body data-ma-theme="green">
    <main class="main">
      <section class="">
        <div class="fluid-container">
          <div class="row m-0 p-0 d-none">
            <div class="col-12 p-0 m-0 hidden-sm">
              <div class="login-bg mb-0 d-flex justify-content-center">

                <div class="banner-content mt-5 mb-0 ">
                  <h1 class="">
                    <strong class="landing-title">COUNTY GOVERNMENT OF Nairobi County</strong>
                  </h1>


                  <div class="col-12 p-0 line my-4"></div>


                  <div class="col-12 landing-module-header mt-5 p-0">
                    <div class="col-3 p-0">
                                        <span class="landing-page-module mr-2 text-nowrap"><strong>COUNTY GOVERNMENT OF Nairobi County system
                                                Modules</strong></span>
                    </div>
                  </div>

                  <span class="module-holder">
                                    <!-- Inspection -->
                                    <a target="new" href="https://test.revenuesure.co.ke/onboarding/public/user.login"
                                       class="module-content my-3 mr-3">
                                        <div><span class="flaticon-user-avatar-with-check-mark"></span></div>
                                        <span class="module-content-text">Dashbord</span>
                                    </a>

                    <!-- Reports -->
                                    <a target="new" href="https://test.revenuesure.co.ke/purchase/public/login"
                                       class="module-content my-3 mx-3">
                                        <div><span class="flaticon-analytics"></span></div>
                                        <span class="module-content-text">School</span>
                                    </a>


                                </span>
                </div>


              </div>
            </div>
          </div>

          <div class="row  the-main-container-main">
            <div class="contaner-header-area">
              <div class="mt-2 saf-logo">
                <img src="../../public/logos/county_logo.png" alt="" class="img">
              </div>
              <p class="mb-0 pb-0 d-none">
                Available Platforms
              </p>
              <div>
               <!-- <img src="../../public/logos/laptop.png">-->
              </div>
            </div>
            <div class="modules-container">
              <small class="pb-5">Select module</small>
              <h2>System Modules</h2>
              <p class="pb-5">Here are some of the modules for the system. Select a module.</p>
              <div class="col-sm-12 col-md-10">
                <div class="row">

                  <div class="col-sm-12 col-md-4">
                    <div class="the-module ">
                      <div class="module-icon">
                        <i class="mdi mdi-laptop"></i>
                      </div>
                      <div class="module-info">
                        <h4>Self Service Portal </h4>
                        <p>This is a customer facing application that would enable members of public to access county
                          services.</p>
                        <br>
                        <a target="new" href="https://saf-citizen.paysol.co.ke" class="btn btn-primary btn-sm">View
                          Module <i class="mdi mdi-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-4">
                    <div class="the-module">
                      <div class="module-icon">
                        <i class="mdi mdi-receipt"></i>
                      </div>
                      <div class="module-info">
                        <h4>County Biller</h4>
                        <p>
                          From this portal, you can get to create and receipt county bills.
                        </p>
                        <br>
                        <a target="new" href="https://saf-biller.paysol.co.ke/"
                           class="btn btn-primary btn-sm">View Module <i class="mdi mdi-arrow-right"></i></a>
                        <!--                        <a target="new" href="https://ozzaynetwork.github.io/Elgeyo-biller/"
                                                   class="btn btn-primary btn-sm">View Module <i class="mdi mdi-arrow-right"></i></a>-->
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-4">
                    <div class="the-module">
                      <div class="module-icon">
                        <i class="mdi mdi-car-brake-parking"></i>
                      </div>
                      <div class="module-info">
                        <h4>Parking Dashboard</h4>
                        <p>
                          Portal that provides real-time data on parking per zone, enforcement and analysis of parking
                          revenue
                        </p>
                        <br>
                        <a target="new" href="https://saf-parking.paysol.co.ke/"
                           class="btn btn-primary btn-sm">View Module <i class="mdi mdi-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-4">
                    <div class="the-module">
                      <div class="module-icon ">
                        <i class="flaticon-analytics"></i>
                      </div>
                      <div class="module-info">
                        <h4>Finance Dashboard</h4>
                        <p>
                          Shows a summary of revenue collection and gives comparison between current week and previous
                          week.
                        </p>
                        <a target="new" href="https://saf-finance.paysol.co.ke/"
                           class="btn btn-primary btn-sm">View Module <i class="mdi mdi-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-4">
                    <div class="the-module">
                      <div class="module-icon ">
                        <i class="mdi mdi-billboard"></i>
                      </div>
                      <div class="module-info">
                        <h4>Adverts Dashboard</h4>
                        <p>
                          Generate bills for the different adverts payments, view current status of an advert on a map &
                          the amount paid.
                        </p>
                        <a target="new" href="https://saf-adverts.paysol.co.ke/"
                           class="btn btn-primary btn-sm">View Module <i class="mdi mdi-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-4">
                    <div class="the-module">
                      <div class="module-icon ">
                        <i class="mdi mdi-storefront-outline"></i>
                      </div>
                      <div class="module-info">
                        <h4>Markets Dashboard</h4>
                        <p>
                          Get to see trends and perfomance on the markets and <br> fisheries.
                        </p>
                        <a target="new" href="https://saf-markets.paysol.co.ke/"
                           class="btn btn-primary btn-sm">View Module <i class="mdi mdi-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-4">
                    <div class="the-module">
                      <div class="module-icon ">
                        <i class="mdi mdi-office-building-marker-outline"></i>
                      </div>
                      <div class="module-info">
                        <h4>eConstruction</h4>
                        <p>
                          Get to see trends and performance on the eConstruction.
                          <br>
                        </p>
                        <a target="new" href="https://saf-econstruction.paysol.co.ke/"
                           class="btn btn-primary btn-sm">View Module <i class="mdi mdi-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-4">
                    <div class="the-module">
                      <div class="module-icon ">
                        <i class="mdi mdi-office-building-marker-outline"></i>
                      </div>
                      <div class="module-info">
                        <h4>Health</h4>
                        <p>
                          Get to see trends and food handlers and Food hygiene.
                          <br>
                        </p>
                        <a target="new" href="https://saf-health.paysol.co.ke/"
                           class="btn btn-primary btn-sm">View Module <i class="mdi mdi-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 pt-4 d-none">
                    <!--                  <small class="mr-3">Powered by</small>
                                      <img class="img" height="40px" src="demo/img/logo.svg">-->
                  </div>

                </div>
              </div>
            </div>
          </div>


        </div>
      </section>
    </main>
    </body>

  </div>
</template>


<script>

  import {baseURL} from "@/api";


  export default {
    name: "HomePage",

    data(){
      return{
        message:'',
        baseURL2: baseURL
      }
    },
    mounted() {
    },
    methods:{

    }
  }
</script>

<style scoped>

</style>